export const MAIN_PAGE = '/'

enum PaymentPages {
  Success = 'success',
  Failed = 'failed',
  Interrupt = 'interrupt',
  Methods = 'methods',
  Token = 'token',
}
export const PAYMENT_SUCCESS_PAGE = `${MAIN_PAGE}${PaymentPages.Success}`
export const PAYMENT_FAILED_PAGE = `${MAIN_PAGE}${PaymentPages.Failed}`
export const PAYMENT_INTERRUPT_PAGE = `${MAIN_PAGE}${PaymentPages.Interrupt}`

export const METHODS_PAGE = `${MAIN_PAGE}${PaymentPages.Methods}`

// Для разработчиков и тестеровщиков
export const TOKEN_PAGE = MAIN_PAGE + PaymentPages.Token
