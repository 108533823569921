import { FC } from 'react'
import { OrderInfoProps, OrderInfoTotal } from './order-info.types'
import styles from './order-info.module.scss'
import { ReactComponent as SpinnerLargeWhiteIcon } from '../../shared/icons/spinner-large-white.svg'

export const OrderInfo: FC<OrderInfoProps> = ({ orderInfo }) => {
  const positions = orderInfo.positions
  const total = orderInfo.total as OrderInfoTotal

  return (
    <div className={styles['container']}>
      <div className={styles['positions-block']}>
        {positions.map((position) => (
          <div className={styles['position']}>
            <div className={styles['position-left-block']}>
              <SpinnerLargeWhiteIcon width="40px" height="40px" />
            </div>
            <div className={styles['position-right-block']}>
              <p className={styles['position-right-block-row-title']}>
                {position.title}
              </p>
              {position.description.map((info: any) => (
                <p className={styles['position-right-block-row-description']}>
                  <span
                    className={
                      styles['position-right-block-row-description-title']
                    }
                  >
                    {info.title}
                  </span>
                  <span
                    className={
                      styles['position-right-block-row-description-value']
                    }
                  >
                    {info.value}
                  </span>
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles['total-block']}>
        <span>{total.title}</span>
        <span>{total.value}</span>
      </div>
    </div>
  )
}
