import type { FC } from 'react'

import { useEffect } from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router'

import { LazyLoader } from '@/entities'
import { TOKEN_PAGE } from '@/shared/constants/routes'
import { useLanguage } from '@/shared/contexts/language-context'
import {
  PaymentTokenContextProvider,
  usePaymentTokenContext,
} from '@/shared/contexts/token-context'
import { LocalStorageKeys } from '@/shared/constants/local-storage'
import {
  PaymentDataContextProvider,
  usePaymentDataContext,
} from '@/shared/contexts/payment-data-context'

const PaymentRoutes: FC = () => {
  const navigate = useNavigate()

  const { loading: languageLoading, changeLanguage } = useLanguage()
  const { loading: tokenLoading, data, token } = usePaymentTokenContext()
  const { loading: paymentDataLoading } = usePaymentDataContext()

  const tokenPageMatch = useMatch(TOKEN_PAGE)

  const loading = tokenLoading || paymentDataLoading
  // || languageLoading

  useEffect(() => {
    if (data) {
      changeLanguage(
        data.language_code ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
      ).then(({ language_code }) => {
        localStorage.setItem(LocalStorageKeys.Language, language_code)
        return
      })
    }
  }, [data])

  useEffect(() => {
    if (process.env.REACT_APP_SETTINGS_PAGE && !tokenLoading && !token) {
      navigate({
        pathname: TOKEN_PAGE,
      })
    }
  }, [tokenLoading, token])

  return process.env.REACT_APP_SETTINGS_PAGE && !!tokenPageMatch ? (
    <Outlet />
  ) : loading ? (
    <LazyLoader />
  ) : (
    <Outlet />
  )
}

const PaymentRoutesWrapper: FC = () => {
  return (
    <PaymentTokenContextProvider>
      <PaymentDataContextProvider>
        <PaymentRoutes />
      </PaymentDataContextProvider>
    </PaymentTokenContextProvider>
  )
}

export { PaymentRoutesWrapper as PaymentRoutes }
function usePaymentContext(): {
  loading: any
  savedCardsLoading: any
  currenciesLoading: any
} {
  throw new Error('Function not implemented.')
}
