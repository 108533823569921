import type { FC } from 'react'

import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Button } from '@/shared/components/button'
import { usePaymentDataContext } from '@/shared/contexts/payment-data-context'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { METHODS_PAGE, TOKEN_PAGE } from '@/shared/constants/routes'

import { getFrameScr } from './utils/get-frame-src'

import styles from './main-page.module.scss'
import { Layout } from '@/widgets'
import { OrderInfo } from '@/entities'
import { useListenerEvents } from '@/shared/hooks/useListenerEvents'
import clsx from 'clsx'

export const MainPage: FC = () => {
  const navigate = useNavigate()
  const { showFullScreen } = useListenerEvents()

  const { params } = useSearchParams()
  const { orderInfo, currentMethod, frameToken } = usePaymentDataContext()

  const [frameSrc, setFrameSrc] = useState(
    getFrameScr({ method: currentMethod, token: frameToken }),
  )

  const toMethodsSelect = useCallback(() => {
    navigate({
      pathname: METHODS_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [params])

  useEffect(() => {
    setFrameSrc(getFrameScr({ method: currentMethod, token: frameToken }))
  }, [currentMethod, frameSrc])

  return (
    <>
      <div
        className={clsx(styles['main-page-order'], {
          [styles['main-page-order-showFullScreen']]: showFullScreen,
        })}
      >
        <Layout
          leftButton={
            <Button onClick={() => navigate(TOKEN_PAGE)}>Назад</Button>
          }
          title="Оформление заказа"
        >
          {orderInfo && <OrderInfo orderInfo={orderInfo} />}
        </Layout>
        <Button onClick={toMethodsSelect}>to methods select</Button>
      </div>
      <div
        className={clsx(styles['main-page-payment'], {
          [styles['main-page-order-showFullScreen']]: showFullScreen,
        })}
      >
        {frameSrc ? (
          <iframe width="100%" height="100%" src={frameSrc} />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
