import type { FC } from 'react'
import type {
  IPaymentDataContext,
  PaymentDataContextProviderProps,
} from './payment-data-context.types'

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'

import { apiService } from '@/shared/api'
import { Headers } from '@/shared/constants/headers'

import { usePaymentTokenContext } from '../token-context'
import { useSearchParams } from '../search-params-context'
import { useNavigate } from 'react-router'
import { METHODS_PAGE } from '@/shared/constants/routes'

const paymentDataContextDefaultValue: IPaymentDataContext = {
  loading: true,
  orderInfo: null,
  methods: [],
  error: null,
  currentMethod: null,
  frameToken: null,
  selectMethod: () => Promise.reject(),
}

export const PaymentDataContext = createContext<IPaymentDataContext>(
  paymentDataContextDefaultValue,
)

export const PaymentDataContextProvider: FC<
  PaymentDataContextProviderProps
> = ({ children }) => {
  const navigate = useNavigate()

  const abortController = useRef<null | AbortController>(null)

  const {
    token,
    data: tokenData,
    loading: isTokenLoading,
  } = usePaymentTokenContext()
  const { params } = useSearchParams()

  const [isLoading, setLoading] = useState(
    paymentDataContextDefaultValue.loading,
  )
  const [error, setError] = useState(paymentDataContextDefaultValue.error)

  const [orderInfo, setOrderInfo] = useState(
    paymentDataContextDefaultValue.orderInfo,
  )

  const [methods, setMethods] = useState(paymentDataContextDefaultValue.methods)
  const [currentMethod, setCurrentMethod] = useState(
    paymentDataContextDefaultValue.currentMethod,
  )

  const [frameToken, setFrameToken] = useState(
    paymentDataContextDefaultValue.frameToken,
  )

  const selectMethod = useCallback<IPaymentDataContext['selectMethod']>(
    (method) => {
      return apiService.payment
        .createTokenForGatePaymentTokensPost(
          {
            payment_method_id: method.method.id,
            payment_system_id: method.system_id,
          },
          {
            headers: {
              [Headers.Token]: token,
            },
          },
        )
        .then(({ data: token }) => {
          setFrameToken(token)
          setCurrentMethod(method)
          if (process.env.REACT_APP_SETTINGS_PAGE) {
            console.info(method, token)
          }
        })
    },
    [token],
  )

  useEffect(() => {
    if (!isTokenLoading && tokenData) {
      setLoading(true)
      setOrderInfo(paymentDataContextDefaultValue.orderInfo)
      setMethods(paymentDataContextDefaultValue.methods)
      setCurrentMethod(paymentDataContextDefaultValue.currentMethod)
      setFrameToken(paymentDataContextDefaultValue.frameToken)

      //   Отменяем предыдущий запрос, если он выполняется
      abortController.current?.abort()
      abortController.current = new AbortController()

      if (token) {
        const promises = [
          apiService.payment
            .getOrderInfoPaymentOrderInfoGet({
              headers: {
                [Headers.Token]: token,
              },
              signal: abortController.current.signal,
            })
            .then(({ data: orderInfo }) => {
              setOrderInfo(orderInfo)
            }),

          apiService.payment
            .getPaymentMethodsPaymentMethodsGet({
              headers: {
                [Headers.Token]: token,
              },
              signal: abortController.current.signal,
            })
            .then(({ data }) => {
              setMethods(data)

              const currentMethod =
                data.find((method) => !!method.was_used_last) ??
                data.find((method) => method.method.code === 'bank_card')
              if (currentMethod) {
                return selectMethod(currentMethod)
              } else {
                navigate({
                  pathname: METHODS_PAGE,
                  search: new URLSearchParams(params).toString(),
                })
              }
            }),
        ]

        Promise.all(promises)
          .catch((err) => {
            console.error(err)
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [isTokenLoading, tokenData])

  return (
    <PaymentDataContext.Provider
      value={{
        loading: isLoading,
        error,
        orderInfo,
        methods,
        currentMethod,
        frameToken,
        selectMethod,
      }}
    >
      {children}
    </PaymentDataContext.Provider>
  )
}

export const usePaymentDataContext = () => useContext(PaymentDataContext)
