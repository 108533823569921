import { useNavigate } from 'react-router'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { useEffect, useState } from 'react'
import {
  MAIN_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_INTERRUPT_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'

interface Handlers {
  success_page_open: () => void
  success_back_button_click: () => void
  interrupt_page_open: () => void
  interrupt_retry_button_click: () => void
  failed_page_open: () => void
  failed_retry_button_click: () => void
  show_iframe_full_screen: () => void
  show_iframe_small_screen: () => void
}

interface MessageData {
  event: keyof Handlers
}

export const useListenerEvents = () => {
  const navigate = useNavigate()
  const { params } = useSearchParams()
  const [showFullScreen, setShowFullScreen] = useState(false)

  const handlers: Handlers = {
    success_page_open: () => {
      navigate({
        pathname: PAYMENT_SUCCESS_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },
    success_back_button_click: () => {
      navigate({
        pathname: MAIN_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },

    interrupt_page_open: () => {
      navigate({
        pathname: PAYMENT_INTERRUPT_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },
    interrupt_retry_button_click: () => {
      alert('interrupt_retry_button_click')
    },

    failed_page_open: () => {
      navigate({
        pathname: PAYMENT_FAILED_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },
    failed_retry_button_click: () => {
      alert('failed_retry_button_click')
    },
    show_iframe_full_screen: () => {
      setShowFullScreen(true)
    },
    show_iframe_small_screen: () => {
      setShowFullScreen(false)
    },
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        if (event.origin === window.location.origin) {
          return
        }
        const data = event.data as MessageData
        if (handlers[data.event]) {
          handlers[data.event]()
        }
      } catch (error) {
        console.error('event error:', error)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return { showFullScreen, setShowFullScreen }
}
