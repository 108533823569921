import { Api } from './swagger'
import { BACKEND_URL } from '@/shared/constants/api'
import { PAYMENT_INTERRUPT_PAGE } from '../constants/routes'

export const apiService = new Api({
  withCredentials: true,
  baseURL: BACKEND_URL,
})

apiService.instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      response: { status },
    } = error
    if (!error.response) return Promise.reject(error)

    if (status >= 500) {
      window.location.href = PAYMENT_INTERRUPT_PAGE
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)
