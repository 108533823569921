import type { PaymentMethodInfo } from '@/shared/api/swagger'
import { SearchParams } from '@/shared/constants/search-params'

export const getFrameScr = ({
  method,
  token,
}: {
  method: PaymentMethodInfo | null
  token: null | string
}) => {
  if (method && token) {
    return (
      method.gate_url +
      '?' +
      new URLSearchParams({
        [SearchParams.Token]: token,
      }).toString()
    )
  }

  return ''
}
