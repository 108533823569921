import type { FC } from 'react'

import { useState, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router'

import { Typography } from '@/shared/components/typography'
import { Button } from '@/shared/components/button'
import { Input } from '@/shared/components/input'

import styles from './token-page.module.scss'
import { MAIN_PAGE } from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { apiService } from '@/shared/api'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'

export const TokenPage: FC = () => {
  const navigate = useNavigate()

  const { setToken } = usePaymentTokenContext()

  const [newToken, setNewToken] = useState<string | null>(null)

  const generateNewPayload = useCallback(() => {
    const iat = Math.floor(Date.now() / 1000)
    const exp = iat + 30 * 60
    const nbf = iat - 300

    return {
      exp,
      iat,
      nbf,
      order_id: uuid(),
      user_id: uuid(),
      user_email: 'test@gmail.com',
      amount_value: 150013,
      amount_currency: 'RUB',
      country_id: 1,
      merchant_id: 1,
      language_code: 'en',
      region_code: 'eu',
    }
  }, [])

  const [forGenerate, setForGenerate] = useState(
    JSON.stringify(generateNewPayload(), null, 2),
  )

  const deleteToken = () => {
    setNewToken(null)
  }

  const toBuy = useCallback(() => {
    if (newToken) {
      setToken(newToken)
      navigate({
        pathname: MAIN_PAGE,
        search: new URLSearchParams({
          [SearchParams.Token]: newToken,
        }).toString(),
      })
    } else {
      apiService.dev
        .createMerchantTokenDevMerchantTokensPost(JSON.parse(forGenerate))
        .then(({ data: token }) => {
          setToken(token)
          navigate({
            pathname: MAIN_PAGE,
            search: new URLSearchParams({
              [SearchParams.Token]: token,
            }).toString(),
          })
        })
    }
  }, [newToken, forGenerate])

  return (
    <div className={styles['payment']}>
      <div className={styles['payment-body']}>
        <Typography
          fontSize="15"
          lineHeight="20"
          letterSpacing="mini"
          color="white-90"
        >
          Token:
        </Typography>
        <div className={styles['generate-block']}>
          <div className={styles['generate-block-row']}>
            <Input
              value={newToken ?? ''}
              onChange={({ target }) =>
                setNewToken((target as HTMLInputElement).value)
              }
            />
            <Button
              onClick={deleteToken}
              type="submit"
              size="small"
              variant="outline"
            >
              Удалить jwt
            </Button>
          </div>
        </div>
        <div className={styles['generate-block']}>
          <div className={styles['generate-block-row']}>
            <textarea
              value={forGenerate}
              onChange={({ target: { value } }) => setForGenerate(value)}
            />
          </div>
        </div>
        <div className={styles['generate-block-row']}>
          <Button
            onClick={() =>
              setForGenerate(JSON.stringify(generateNewPayload(), null, 2))
            }
            type="submit"
            size="small"
            variant="outline"
          >
            Перегенерировать payload
          </Button>
        </div>
        <div className={styles['generate-block-row']}>
          <Button onClick={toBuy} type="submit" size="small" variant="outline">
            К оплате
          </Button>
        </div>
      </div>
    </div>
  )
}
