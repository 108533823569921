import type { FC } from 'react'
import type { InputProps } from './input.types'

import { useEffect, useRef, useImperativeHandle } from 'react'

import { ReactComponent as HintIcon } from '@/shared/icons/hint-icon.svg'

import clsx from 'clsx'
import styles from './input.module.scss'
import 'tippy.js/dist/tippy.css'
import { Tooltip } from '../tooltip'

const DefaultTooltipIcon: FC = () => <HintIcon />

export const Input: FC<InputProps> = ({
  upperCaseOnly = false,
  leftIcon,
  rightIcon,
  description,
  state,
  disabled = false,
  label,
  tooltipText = '',
  tooltipIcon = <DefaultTooltipIcon />,
  inputRef: inputRefProp,
  containerRef: containerRefProp,
  className,
  value,
  onPaste,
  descriptionIgnoreParentWidth,
  onNext = () => {},
  system,
  type = 'text',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(inputRefProp, () => inputRef.current, [inputRef])
  useImperativeHandle(containerRefProp, () => containerRef.current, [
    containerRef,
  ])

  const inputWrapperStyles = clsx(
    styles['input-wrapper'],
    descriptionIgnoreParentWidth &&
      styles['input-wrapper-description-ignore-size'],
    className,
  )
  const inputContainerStyles = clsx(
    styles['input-container'],
    styles[`input-container_${state}`],
    disabled && styles['input-container_disabled'],
  )
  const inputDescriptionStyles = clsx(
    styles['input-description'],
    styles[`input-description_${state}`],
    descriptionIgnoreParentWidth &&
      styles[`input-description_ignore-parent-size`],
    disabled && styles['input-description_disabled'],
  )
  const inputLabelStyles = clsx(
    styles['input-label'],
    disabled ? styles['input-label_disabled'] : null,
  )
  const inputStyles = clsx(
    upperCaseOnly && styles['input_upper-case-only'],
    styles['input'],
    disabled && styles['input_disabled'],
  )

  useEffect(() => {
    if (inputRef?.current) {
      const onFocus = () => {
        if (
          !containerRef.current?.classList.contains(
            styles['input-container_disabled'],
          )
        ) {
          containerRef?.current?.classList.add(styles['input-container_hover'])
        }
      }
      const onBlur = () => {
        if (
          !containerRef.current?.classList.contains(
            styles['input-container_disabled'],
          )
        ) {
          containerRef?.current?.classList.remove(
            styles['input-container_hover'],
          )
        }
      }

      const onChange = (e: Event): void => {
        const input = e.target as HTMLInputElement

        // При реализации через стейт из-за перерендера происходит баг с необходимостью два раза вводить символ
        if (input.parentElement) {
          input.parentElement.dataset.empty = String(!input.value.length)
        }
      }

      const onPaste = (e: ClipboardEvent) => {
        const input = e.target as HTMLInputElement

        // При реализации через стейт из-за перерендера происходит баг с необходимостью два раза вводить символ
        if (input.parentElement && e.clipboardData) {
          input.parentElement.dataset.empty = String(
            !e.clipboardData.getData('text'),
          )
        }
      }

      // меняем начальное значение пустоты у инпута
      if (inputRef.current.parentElement) {
        inputRef.current.parentElement.dataset.empty = String(!value?.length)
      }

      inputRef.current.addEventListener('focus', onFocus)
      inputRef.current.addEventListener('blur', onBlur)
      inputRef.current.addEventListener('input', onChange)
      inputRef.current.addEventListener('paste', onPaste)

      return () => {
        inputRef.current?.removeEventListener('focus', onFocus)
        inputRef.current?.removeEventListener('blur', onBlur)
        inputRef.current?.removeEventListener('input', onChange)
        inputRef.current?.removeEventListener('paste', onPaste)
      }
    }
  }, [inputRef, containerRef])

  return (
    <div className={inputWrapperStyles}>
      <label className={inputLabelStyles} htmlFor="custom-input">
        {label}
      </label>
      <div className={inputContainerStyles} ref={containerRef}>
        {leftIcon && <div className={styles['left-icon']}>{leftIcon}</div>}
        <input
          disabled={disabled}
          className={inputStyles}
          ref={inputRef}
          value={value}
          type={type}
          {...props}
        />
        {tooltipText && <Tooltip Icon={tooltipIcon} content={tooltipText} />}
      </div>
      <span className={inputDescriptionStyles}>{description}</span>
    </div>
  )
}
