import type { FC } from 'react'

import { Routes as ReactRoutes, Route } from 'react-router-dom'

import {
  MAIN_PAGE,
  METHODS_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_INTERRUPT_PAGE,
  PAYMENT_SUCCESS_PAGE,
  TOKEN_PAGE,
} from '@/shared/constants/routes'
import { MainPage } from '@/pages/main-page'
import { PaymentFailed, PaymentSuccess } from '@/pages/payment-result'
import { PaymentInterrupt } from '@/pages/payment-result/payment-interrupt'
import { TokenPage } from '@/pages/token-page'

import { CustomRedirect } from './custom-redirect'
import { PaymentRoutes } from './protected-routes/payment-routes/payment-route'
import { MethodsPage } from '@/pages/methods-page'

export const Routes: FC = () => {
  return (
    <ReactRoutes>
      <Route path={MAIN_PAGE} element={<PaymentRoutes />}>
        {process.env.REACT_APP_SETTINGS_PAGE && (
          <Route path={TOKEN_PAGE} element={<TokenPage />} />
        )}

        <Route path={METHODS_PAGE} element={<MethodsPage />} />
        <Route path={MAIN_PAGE} element={<MainPage />} />
      </Route>

      <Route path={PAYMENT_FAILED_PAGE} element={<PaymentFailed />} />
      <Route path={PAYMENT_INTERRUPT_PAGE} element={<PaymentInterrupt />} />
      <Route path={PAYMENT_SUCCESS_PAGE} element={<PaymentSuccess />} />

      <Route path={'*'} element={<CustomRedirect />} />
    </ReactRoutes>
  )
}
